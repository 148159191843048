import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
*,
::after,
::before {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin:0;
    padding:0;
    scroll-behavior: smooth; 
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
body, html {
    font-family: ${props => props.theme.fonts.primary};
    height: 100%;
    background-color: ${props => props.theme.colors.bg};
}
div {
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ul {
  list-style: none;
  list-style-type: none;
  /* margin-bottom: 0px; */
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: ${props => props.theme.spacing};
  text-transform: capitalize;
  line-height: 1.25;
  ${"" /* margin-bottom: 0.75rem; */}
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 600;
}
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection,
p::selection {
  background-color: ${props => props.theme.colors.selection};
}
h1 {
  font-size: 3rem;
  color: ${props => props.theme.colors.H1H2};
}
h2 {
  font-size: 2rem;
  color: ${props => props.theme.colors.H1H2};
}
h3 {
  font-size: 1.25rem;
  color: ${props => props.theme.colors.H3toH6};
}
h4 {
  font-size: 0.875rem;
  color: ${props => props.theme.colors.H3toH6};
}
h5,h6 {
  color: ${props => props.theme.colors.H3toH6};
}
@media screen and (min-width: 600px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
p {
  margin-bottom: 1.25rem;
  color: ${props => props.theme.colors.p};

  font-family: ${props => props.theme.fonts.secondary};
  -webkit-font-smoothing: antialiased;
}
p a {
  display: inline;
  position: relative;
  border-bottom: solid 3px ${props => props.theme.colors.aUnderline};
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  background: ${props => props.theme.colors.aBg};
  padding: 0 0.2rem;
  color: ${props => props.theme.colors.aText};
  box-shadow: ${props => props.theme.elevations.elevation1};
}
p:last-of-type {
  margin-bottom: 0;
}
a,
a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
p a:hover {
  background: ${props => props.theme.colors.aHoverBg};
  border-bottom: solid 3px ${props => props.theme.colors.AHoverUnderline};
}
p a:active {
  position: relative;
  background-color: ${props => props.theme.colors.aActiveBg};
  border-bottom: solid 3px ${props => props.theme.colors.aActiveUnderline};
}
p a:hover::after {
  opacity: 0.2;
}
section {
  display: block;
  position: relative;
  box-sizing: border-box;
}
`
export default GlobalStyles
