export default {
  colorPrimary100: "#D3FDFA",
  colorPrimary200: "#A9FCFC",
  colorPrimary300: "#7CF0F8",
  colorPrimary400: "#5BDDF2",
  colorPrimary500: "#28C1EA",
  colorPrimary600: "#1D98C9",
  colorPrimary700: "#1473A8",
  colorPrimary800: "#0C5287",
  colorPrimary900: "#073B70",
  colorPrimarytransparent100: "rgba(40, 193, 234, 0.08)",
  colorPrimarytransparent200: "rgba(40, 193, 234, 0.16)",
  colorPrimarytransparent300: "rgba(40, 193, 234, 0.24)",
  colorPrimarytransparent400: "rgba(40, 193, 234, 0.32)",
  colorPrimarytransparent500: "rgba(40, 193, 234, 0.4)",
  colorPrimarytransparent600: "rgba(40, 193, 234, 0.48)",
  colorSecondary100: "#e2f0f6",
  colorSecondary200: "#c7e1ee",
  colorSecondary300: "#9ab9cc",
  colorSecondary400: "#6a8599",
  colorSecondary500: "#334556",
  colorSecondary600: "#253649",
  colorSecondary700: "#19283d",
  colorSecondary800: "#101c31",
  colorSecondary900: "#091329",
  colorSecondarytransparent100: "rgba(51, 69, 86, 0.08)",
  colorSecondarytransparent200: "rgba(51, 69, 86, 0.16)",
  colorSecondarytransparent300: "rgba(51, 69, 86, 0.24)",
  colorSecondarytransparent400: "rgba(51, 69, 86, 0.32)",
  colorSecondarytransparent500: "rgba(51, 69, 86, 0.4)",
  colorSecondarytransparent600: "rgba(51, 69, 86, 0.48)",
  colorSuccess100: "#D8FCE6",
  colorSuccess200: "#B2FAD5",
  colorSuccess300: "#89F0C4",
  colorSuccess400: "#68E1BA",
  colorSuccess500: "#3BCEAC",
  colorSuccess600: "#2BB19F",
  colorSuccess700: "#1D948F",
  colorSuccess800: "#127177",
  colorSuccess900: "#0B5562",
  colorSuccesstransparent100: "rgba(59, 206, 172, 0.08)",
  colorSuccesstransparent200: "rgba(59, 206, 172, 0.16)",
  colorSuccesstransparent300: "rgba(59, 206, 172, 0.24)",
  colorSuccesstransparent400: "rgba(59, 206, 172, 0.32)",
  colorSuccesstransparent500: "rgba(59, 206, 172, 0.4)",
  colorSuccesstransparent600: "rgba(59, 206, 172, 0.48)",
  colorInfo100: "#CBFEFC",
  colorInfo200: "#98FAFE",
  colorInfo300: "#65ECFD",
  colorInfo400: "#3ED9FB",
  colorInfo500: "#00BBF9",
  colorInfo600: "#0091D6",
  colorInfo700: "#006DB3",
  colorInfo800: "#004D90",
  colorInfo900: "#003777",
  colorInfotransparent100: "rgba(0, 187, 249, 0.08)",
  colorInfotransparent200: "rgba(0, 187, 249, 0.16)",
  colorInfotransparent300: "rgba(0, 187, 249, 0.24)",
  colorInfotransparent400: "rgba(0, 187, 249, 0.32)",
  colorInfotransparent500: "rgba(0, 187, 249, 0.4)",
  colorInfotransparent600: "rgba(0, 187, 249, 0.48)",
  colorWarning100: "#FFF9D8",
  colorWarning200: "#FFF2B2",
  colorWarning300: "#FFE98B",
  colorWarning400: "#FFE06F",
  colorWarning500: "#FFD23F",
  colorWarning600: "#DBAE2E",
  colorWarning700: "#B78C1F",
  colorWarning800: "#936C14",
  colorWarning900: "#7A550C",
  colorWarningtransparent100: "rgba(255, 210, 63, 0.08)",
  colorWarningtransparent200: "rgba(255, 210, 63, 0.16)",
  colorWarningtransparent300: "rgba(255, 210, 63, 0.24)",
  colorWarningtransparent400: "rgba(255, 210, 63, 0.32)",
  colorWarningtransparent500: "rgba(255, 210, 63, 0.4)",
  colorWarningtransparent600: "rgba(255, 210, 63, 0.48)",
  colorDanger100: "#FEDFD9",
  colorDanger200: "#FDB9B4",
  colorDanger300: "#F98D8F",
  colorDanger400: "#F4707F",
  colorDanger500: "#EE4266",
  colorDanger600: "#CC305F",
  colorDanger700: "#AB2158",
  colorDanger800: "#8A154F",
  colorDanger900: "#720C48",
  colorDangertransparent100: "rgba(238, 66, 102, 0.08)",
  colorDangertransparent200: "rgba(238, 66, 102, 0.16)",
  colorDangertransparent300: "rgba(238, 66, 102, 0.24)",
  colorDangertransparent400: "rgba(238, 66, 102, 0.32)",
  colorDangertransparent500: "rgba(238, 66, 102, 0.4)",
  colorDangertransparent600: "rgba(238, 66, 102, 0.48)",
  bg: "#142024",
  bg1: "#263135",
  bg2: "#3A4447",
  bg3: "#4C5558",
  bg4: "#60686A",
  bg5: "#72797C",
  bg6: "#848B8D",
  SGrey1: "#EBEDEF",
  SGrey2: "#D6DADD",
  SGrey3: "#C2C8CD",
  SGrey4: "#ADB5BB",
  SGrey5: "#99A2AB",
  SGrey6: "#868F9A",
  SGrey7: "#727D89",
  SGrey8: "#5C6A78",
  SGrey9: "#485867",
  PBlue1: "#EBF9FD",
  PBlue2: "#D4F3FB",
  PBlue3: "#BFEDF9",
  PBlue4: "#A9E6F7",
  PBlue5: "#94E0F5",
  PBlue6: "#7EDAF2",
  PBlue7: "#69D4F1",
  PBlue8: "#53CDEE",
  PBlue9: "#3EC8ED",
  gradients: {
    light: {
      gradient: "linear-gradient(270deg, #85DCF3 0%, #E2F7FC 100%)",
      gradientWebkit:
        "-webkit-gradient(linear, right top, left top, from(#85DCF3), to(#E2F7FC))",
      gradientO: "-o-linear-gradient(right, #85DCF3 0%, #E2F7FC 100%)",
    },
    dark: {
      gradient: "linear-gradient(90deg, #28c1ea -22.5%, #0096d6 122.5%)",
      gradientWebkit:
        "-webkit-gradient(linear,left top,right top,color-stop(-22.5%, #28c1ea),color-stop(122.5%, #0096d6))",
      gradientO: "-o-linear-gradient(left, #28c1ea -22.5%, #0096d6 122.5%)",
    },
  },
}
