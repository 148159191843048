import React from "react"
import { ThemeProvider } from "styled-components"
import lightTheme from "../themes/lightTheme"
import darkTheme from "../themes/darkTheme"
import GlobalStyles from "./GlobalStyles"

// Our global theme context with default values
export const ThemeContext = React.createContext({
  darkMode: false,
  setDarkMode: () => {},
  isOpen: false,
  setIsOpen: () => {},
})

// Theme provider component with state
const MyThemeProvider = props => {
  const [darkMode, setDarkMode] = React.useState(false)
  const handleClick = e => {
    e.preventDefault()
    setDarkMode(!darkMode)
  }

  const [isOpen, setIsOpen] = React.useState(false)
  const toggleSideMenu = () => {
    setIsOpen(!isOpen)
  }

  const value = {
    darkMode,
    setDarkMode,
    handleClick,
    isOpen,
    setIsOpen,
    toggleSideMenu,
  }

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <GlobalStyles />
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

// Exports a ThemeProvider wrapper
export default ({ element }) => <MyThemeProvider>{element}</MyThemeProvider>
